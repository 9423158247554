import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PDF from "../documents/ArikParkerResume.pdf"
import PadBox from "@bedrock-layout/padbox"
import Center from "@bedrock-layout/center"
import styled from "styled-components"

const Title = styled.h1`
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 4px rgba(20, 20, 20, 0.4);
`

const FullPageBackground = styled(PadBox)`
  min-height: 100vh;
`
const Anchor = styled.a`
  text-decoration: none;
  color: #ed7723;
  font-weight: bolder;
`
const InternalLink = styled(Link)`
  text-decoration: none;
  color: #ed7723;
  font-weight: bolder;
`

const About = () => (
  <Layout>
    <SEO title="About" />
    <FullPageBackground
      style={{ background: "#255b77" }}
      justify="center"
      padding={["xl"]}
    >
      <Center maxWidth={900}>
        <Title>About Me</Title>
        <PadBox style={{ color: "#fff" }}>
          After years of working on professional and personal projects for
          myself and others out of necessity, I realized that the process of
          imagining, creating, and executing digital and print marketing
          materials was a challenge that I truly enjoyed! Designing logos,
          brochures and websites is an endeavor that requires creative thinking
          and technical ability, and delivering these to satisfy clients
          requires excellent communication. These traits, combined with a desire
          and goal to learn something new every day, have enabled me to grow as
          a developer and designer and feel a great sense of personal and
          professional fulfillment.
          <br />
          <br />I currently work full-time as a Front End software developer
          with a large international company. I get to use cool, modern tools
          including{" "}
          <Anchor href="https://reactjs.org/" target="_blank">
            React
          </Anchor>
          ,{" "}
          <Anchor href="https://www.typescriptlang.org/" target="_blank">
            TypeScript
          </Anchor>
          , and{" "}
          <Anchor href="https://styled-components.com/" target="_blank">
            styled-components
          </Anchor>{" "}
          to build software for both internal and external use. Additionally, I
          do freelance work with clients across multiple states and industries,
          including land development, interior design, woodworking, music and
          more. I have significant experience working within Adobe’s Creative
          Cloud, and experience working with CMS including WordPress,
          Squarespace, and Joomla. In 2018 I completed the Grow with Google
          scholarship program for front end web development. I also hold a
          Master of Public Policy (MPP) and Bachelor’s degrees (BS) in
          Environmental Studies and Geography, all from the University of Utah.
          I’m aspiring to become a full-stack developer, and strive to continue
          learning and getting better every day. Please feel free to check out
          my{" "}
          <Anchor href={PDF} target="_blank">
            resume
          </Anchor>{" "}
          and <InternalLink to="../portfolio">portfolio</InternalLink>, and drop
          me a line if I can help you with anything!
        </PadBox>
      </Center>
    </FullPageBackground>
  </Layout>
)

export default About
